import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="flex justify-center items-center w-full">
        <div className="w-full flex flex-col">
        <span className="containBorder w-full h-7 2xl:translate-y-[2px] xl:translate-y-[2px] lg:translate-y-[2px] translate-y-[15px]" />
          <div className="flex bg-yello w-full justify-center items-center px-2 py-3 divide-x-2 divide-gray-900">
          <p className="text-gray-900 px-3 font-semibold 2xl:text-base xl:text-base lg:text-sm text-xs text-start font-['Play']">
            @2023 TWELVE ZODIAC ALL RIGHTS RESERVED
          </p>
          <Link to="/privacy" className="text-gray-900 font-black px-3 2xl:text-base xl:text-base lg:text-sm text-xs text-center font-['Play'] uppercase">
            <p>Privacy Policy</p> 
          </Link>
        </div>
        </div>
      </footer>
  )
}

export default Footer
