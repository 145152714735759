import React from 'react';
import logo from "../asset/Logo/Twelve.png";

const Privacy = () => {
  return (
    <div
    className={`privacy-policy snap-center flex flex-col items-center justify-center  `}

  >
    <div className="content py-10 2xl:w-[60%] xl:w-[60%] lg:w-[60%] w-[85%] font-['Play'] text-justify text-white">
      <div className="logo pt-14">
        <a href="/">
        <img
          src={logo}
          alt=""
          className="mx-auto 2xl:w-[20%] xl:w-[20%] lg:w-[20%] w-[30%] "
        /></a>
      </div>
      <h1 className="text-center py-5 border-0 text-2xl">Twelve Zodiac Privacy Policy</h1>
     
      <p>
        Twelve Zodiac has adopted this privacy policy (“Privacy Policy”) to
        explain how Twelve Zodiac collects, stores, and uses the information
        collected in connection with Twelve Zodiac’s Services.
      </p>
      <p className="pt-2">
        <b>
          {" "}
          BY INSTALLING, USING, REGISTERING TO OR OTHERWISE ACCESSING THE
          SERVICES, YOU AGREE TO THIS PRIVACY POLICY AND GIVE AN EXPLICIT AND
          INFORMED CONSENT TO THE PROCESSING OF YOUR PERSONAL DATA IN
          ACCORDANCE WITH THIS PRIVACY POLICY. IF YOU DO NOT AGREE TO THIS
          PRIVACY POLICY, PLEASE DO NOT INSTALL, USE, REGISTER TO OR OTHERWISE
          ACCESS THE SERVICES.
        </b>{" "}
        Twelve Zodiac reserves the right to modify this Privacy Policy at
        reasonable times, so please review it frequently. If Twelve Zodiac
        makes material or significant changes to this Privacy Policy, Twelve
        Zodiac may post a notice on Twelve Zodiac’s website along with the
        updated Privacy Policy. Your continued use of services will signify
        your acceptance of the changes to this Privacy Policy.
      </p>
      <h2 className="text-center text-2xl pt-5 pb-3">Non-personal data</h2>
      <p>
        For purposes of this Privacy Policy, “non-personal data” means
        information that does not directly identify you. The types of
        non-personal data Twelve Zodiac may collect and use include, but are
        not limited to: application properties, including, but not limited to
        application name, package name and icon installed on your device. Your
        check in (include like, recommendation) of a game will be disclosed to
        all Twelve Zodiac users.
      </p>
      <p className="pt-2">
        Twelve Zodiac may use and disclose to Twelve Zodiac partners and
        contractors the collected non-personal data for purposes of analyzing
        usage of the Services, advertisement serving, managing and providing
        the Services and to further develop the Services and other Twelve
        Zodiac services and products.
      </p>
      <p className="pt-2">
        You recognize and agree that the analytics companies utilized by
        Twelve Zodiac may combine the information collected with other
        information they have independently collected from other services or
        products relating to your activities. These companies collect and use
        information under their own privacy policies.
      </p>
      <h2 className="text-center text-2xl pt-5 pb-3">Personal Data</h2>
      <p>
        For purposes of this Privacy Policy, “personal data” means personally
        identifiable information that specifically identifies you as an
        individual.
      </p>
      <p className="pt-2">
        Personal information collected by Twelve Zodiac is information
        voluntarily provided to us by you when you create your account or
        change your account information. The information includes your google
        play id, name, and gender.
      </p>
      <p className="pt-2">
        Twelve Zodiac may use collected personal data for purposes of
        analyzing usage of the Services, providing customer and technical
        support, managing and providing Services (including managing
        advertisement serving) and to further develop the Services and other
        Twelve Zodiac services and products. Twelve Zodiac may combine
        non-personal data with personal data.
      </p>
      <h2 className="text-center text-2xl pt-5 pb-3">
        Disclosure and Transfer of Personal Data
      </h2>
      <p>
        Twelve Zodiac collects and processes personal data on a voluntary
        basis and it is not in the business of selling your personal data to
        third parties. Personal data may, however, occasionally be disclosed
        in accordance with applicable legislation and this Privacy Policy.
        Additionally, Twelve Zodiac may disclose personal data to its parent
        companies and its subsidiaries in accordance with this Privacy Policy.
      </p>
      <h2 className="text-center text-2xl pt-5 pb-3">Safeguards</h2>
      <p>
        Twelve Zodiac follows generally accepted industry standards and
        maintains reasonable safeguards to attempt to ensure the security,
        integrity and privacy of the information in Twelve Zodiac possession.
        Only those persons with a need to process your personal data in
        connection with the fulfillment of their tasks in accordance with the
        purposes of this Privacy Policy and for the purposes of performing
        technical maintenance, have access to your personal data in Twelve
        Zodiac’s possession. Personal data collected by Twelve Zodiac is
        stored in secure operating environments that are not available to the
        public. To prevent unauthorized on-line access to personal data,
        Twelve Zodiac maintains personal data behind a firewall-protected
        server. However, no system can be 100% secure and there is the
        possibility that despite Twelve Zodiac’s reasonable efforts, there
        could be unauthorized access to your personal data. By using the
        Services, you assume this risk.
      </p>
      <h2 className="text-center text-2xl pt-5 pb-3">Other</h2>
      <p>
        Please be aware of the open nature of certain social networking and
        other open features of the Services Twelve Zodiac may make available
        to you. You may choose to disclose data about yourself in the course
        of contributing user generated content to the Services. Any data that
        you disclose in any of these forums, blogs, chats or the like is
        public information, and there is no expectation of privacy or
        confidentiality. Twelve Zodiac is not responsible for any personal
        data you choose to make public in any of these forums.
      </p>
      <p className="pt-2">
        If you are under 15 years of age or a minor in your country of
        residence, please ask your legal guardian’s permission to use or
        access the Services. Twelve Zodiac takes children’s privacy seriously,
        and encourages parents and/or guardians to play an active role in
        their children's online experience at all times. Twelve Zodiac does
        not knowingly collect any personal information from children below the
        aforementioned age and if Twelve Zodiac learns that Twelve Zodiac has
        inadvertently gathered personal data from children under the
        aforementioned age, Twelve Zodiac will take reasonable measures to
        promptly erase such personal data from Twelve Zodiac’s records.
      </p>
      <div className="w-full flex justify-end items-center pt-10 border-b-2">
       <a href="/">  Back to Home</a> 
      </div>
    </div>
  </div>
  )
}

export default Privacy
