import React from 'react';
import "./SVGTopBorder.css"

const SVGTopBorder = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 1281.64 66.35"
>

 <g id="Layer_x0020_1">

  <polygon className="fil0" points="-0,32.46 33.27,0 1256.46,0 1281.64,23.22 498,23.22 472.32,48.27 101.58,48.27 88.4,34.93 32.2,34.93 -0,66.35 "/>
 </g>
</svg>
  )
}

export default SVGTopBorder
