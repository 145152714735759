import React from "react";
import { Link } from "react-router-dom";
import StripesBtn from "../components/button/StripesBtn/StripesBtn";
import SVGTokenomicGraph from "../components/svgs/SVGTokenomicGraph";
import SVGDevelopment from "../components/svgs/SVGDevelopment";
import SVGTopBorder from "../components/svgs/SVGTopBorder";
import SVGBottomBorder from "../components/svgs/SVGBottomBorder";
import "./Token.css";

const Token = () => {
  return (
    <section className="tokenomic my-44 flex flex-col justify-center items-center">
      <div className="wrapper 2xl:w-[50%] xl:w-[60%] lg:w-[65%] w-[90%] flex flex-col justify-between items-start gap-5">
        <StripesBtn isButton={false}>$ TWELVE</StripesBtn>
        <div className="content-tokenomic flex flex-col gap-8">
          <div className="title">
            <h1 className="text-2xl font-semibold text-white">Tokenomic</h1>
            <p className="subtitle text-white text-justify my-3">
              $Twelve Token is the governance and utility token used
              for Metaverse, seasonal drops, and in-game items. Stake $TWELVE
              for rewards and in-game perks.
            </p>
          </div>
          <div className="tokenInfo">
            <SVGTopBorder />
            <div className="content-tokenInfo w-[96%] rounded-lg bg-[#00ffff]/10 mx-auto my-3 py-8 flex flex-col justify-center items-center text-[#00ffff] font-semibold gap-3">
              <p>Token Name : TWELVE</p>
              <p>Total Supply : 21.000.000 TWELVE</p>
              <p>Network: Binance Smart Chain - BEP20</p>
              <p>Token Decimal : 18</p>
              <p>Token Symbol : $TWELVE</p>
              <p>Smart Contract</p>
              <Link to="https://bscscan.com/token/0xbd6ceeef56985b608252c3651dd903a3fcc34910">
                <p className="underline italic">
                  0xBD6ceeEf56985B608252C3651dd903a3fCc34910
                </p>
              </Link>
            </div>
            <SVGBottomBorder />
          </div>
          <div className="tokenomic">
            <SVGTopBorder />
            <div className="content-tokenomic w-[96%] rounded-lg bg-[#00ffff]/10 mx-auto my-3 py-8 flex justify-center">
              <SVGTokenomicGraph />
            </div>
            <SVGBottomBorder />
          </div>
          <div className="developer">
            <SVGTopBorder />
            <div className="content-developer w-[96%] rounded-lg bg-[#00ffff]/10 mx-auto my-3 py-8 px-3">
              <SVGDevelopment />
            </div>
            <SVGBottomBorder />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Token;
