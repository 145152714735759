import {useState, useEffect, useRef} from 'react';
import { useParallax } from "react-scroll-parallax";
import Logo12 from "../asset/Logo/twelve12.png";
import Eid from "../asset/Eid Mubrks_2.mp4";

const Hero = () => {
    const [floatLogo, setFloatLogo] = useState(false);
    const logoRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const videoRef = useRef(null);

    const handleVideoLoad = () => {
      setLoading(true);
    };
  
    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.setAttribute('playsinline', '');
      }
    }, []);
    const layer1 = useParallax({ speed: -20, translateY: [0, -3] });

    const handleshowModal = () => {
      setShowModal(false);
    }


    useEffect(() => {
        const logo = logoRef.current;
    
        const handleAnimationEnd = () => {
          setFloatLogo(true);
        };
    
        logo.addEventListener("animationend", handleAnimationEnd);
    
        return () => {
          logo.removeEventListener("animationend", handleAnimationEnd);
        };
      }, []);

  return (
<section className={`home w-screen h-screen flex flex-col justify-center relative`}>
        <div
          className={`wrapp flex w-screen h-screen flex-col justify-center items-center overflow-clip bg-white`}
        >
          <span className="bgk1a relative flex flex-col justify-center items-center">
            <img
              ref={logoRef}
              src={Logo12}
              alt=""
              className={`logoNya z-10 2xl:h-[50%] xl:h-[50%] lg:h-[50%] h-[30%] w-auto 2xl:scale-50 xl:scale-50 lg:scale-50 absolute ${
                floatLogo ? "float block" : "animate-logo"
              }`}
            />
            <span
              ref={layer1.ref}
              className="layer-1 -bottom-10 left-0 backdrop-blur-[4px]"
            ></span>
            <span className="layer-2 top-0 left-0 backdrop-blur-[2px]"></span>
          </span>
        </div>
        {/* {showModal && <div className="absolute top-[10%] left-1/2 -translate-x-1/2 2xl:w-[70%] xl:w-[70%] lg:w-[70%] w-full bg-black/50 z-[1000]" >
          <div className="wrap-video ">
            <div className="modals flex w-full justify-end">
            <button onClick={handleshowModal} className='px-2 py-1 text-white hover:font-black'>Close X</button>
            </div>
        <video
              ref={videoRef}
              autoPlay={true}
              loop="loop"
              controlsList='nodownload'
              controls={true}
              src={Eid}
              className={`NFT aspect-video ${loading ? "" : "hidden"}`} // Hide the video when loading is true
              onLoadedData={handleVideoLoad}
            >
              <source src={Eid} />
            </video>
            </div>
            </div>} */}
      </section>
  )
}

export default Hero
