import React from "react";
import StripesBtn from "../components/button/StripesBtn/StripesBtn";
import CT from "../asset/Logo/CoinTiger.png";
import CMC from "../asset/Logo/CoinMC.png";
import BSC from "../asset/Logo/BscScan.png";
import CGC from "../asset/Logo/CoinGecko.png";
import Pinata from "../asset/Logo/pinata.png";
import CTX from "../asset/Logo/certik.png";
import BAP from "../asset/Logo/BAPPEBTI.png";
import INDODAX from "../asset/Logo/IndoDAX.png";
import BW from "../asset/Logo/billionairesWay.png";

const Partnership = () => {
  return (
    <section className="partnership relative my-10 flex justify-center items-center">
      <div className="wrapper 2xl:w-[50%] xl:w-[60%] lg:w-[65%] w-[90%] flex flex-col justify-between items-start gap-5">
        <StripesBtn isButton={false}>Partnership</StripesBtn>
        <div className="about-content relative w-full flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row flex-col-reverse gap-10">
          <div className="partner-wrapper grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 grid-cols-2 gap-5 backdrop-blur-sm backdrop-filter p-2">
          {/* <a
              href="https://bscscan.com/address/0xBD6ceeEf56985B608252C3651dd903a3fCc34910"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={CT} alt="" className="link" />
            </a> */}
            <a
              href="https://bscscan.com/address/0xBD6ceeEf56985B608252C3651dd903a3fCc34910"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={BSC} alt="" className="link" />
            </a>

            <a href="/" target="_blank" rel="noopener noreferrer">
              <img src={Pinata} alt="" className="link" />
            </a>

            <a
              href="https://coinmarketcap.com/currencies/twelve-zodiac/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={CMC} alt="" className="link" />
            </a>

            <a
              href="https://www.coingecko.com/en/coins/twelve-zodiac"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={CGC} alt="" className="link" />
            </a>

            <a
              href="https://indodax.com/market/TWELVEIDR"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={INDODAX} alt="" className="link" />
            </a>

            <a
              href="https://bappebti.go.id/pbk/sk_kep_kepala_bappebti/detail/12673"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={BAP} alt="" className="link" />
            </a>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <img src={CTX} alt="" className="link" />
            </a>
            {/* <a
              href="https://bscscan.com/address/0xBD6ceeEf56985B608252C3651dd903a3fCc34910"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={BW} alt="" className="link" />
            </a> */}
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partnership;