import React from "react";

const SVGMenu = () => {
  const styles = {
    cls1: {
      fill: "#000",
    },
    cls2: {
      fill: "#D0B050",
    },
    cls3: {
      fill: "#fff",
      stroke: "#fff",
      strokeMiterlimit: 10,
      strokeWidth: "4px",
    },
    cls4: {
      fill: "none",
      stroke: "#fff",
      strokeMiterlimit: 50,
      strokeWidth: "2px",
    },
    cls5: {
      fill: "#FAF1BA",
      stroke: "#fff",
      strokeMiterlimit: 50,
      strokeWidth: "2px",
      opacity: 0.5,
      filter: "drop-shadow( 1px 1px 20px rgba(0, 0, 0, .9)) inset",
    },
    shadow: {
      filter: "drop-shadow( 0px 3px 10px rgba(0, 0, 0, .7))",
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
    },
  };
  return (
    

<svg xmlns="http://www.w3.org/2000/svg" width="295px" height="325px" version="1.1" 
viewBox="0 0 2817.569 4147.622"
>
 
 <g>
 
  <g>
   <polygon style={styles.cls5} points="300.47,412.056 2444.801,412.056 2593.621,579.253 2593.621,3679.191 2474.254,3819.923 836.527,3819.923 705.149,3535.913 300.47,3535.913 300.47,412.056 "/>
   <path style={styles.cls2} d="M0 2546.218l146.065 -157.146 0 -2054.276 305.666 -334.796 1050.014 0 76.687 136.048 594.755 0 530.529 0 113.853 131.615 0 603.616 0 2742.258 -378.46 450.583 -334.936 0 -148.225 -179.912 -694.319 0 -148.223 179.912 -773.433 0 -339.973 -483.954 0 -1033.948zm451.731 -2480.73l-232.148 269.308 232.148 0 0 -269.308z"/>
   <polygon style={styles.cls2} points="24.423,2460.302 24.423,2078.565 104.454,1959.193 103.492,2355.539 "/>
   <polygon style={styles.cls2} points="24.423,1942.165 24.423,1560.427 104.454,1441.055 103.492,1837.401 "/>
   <polygon style={styles.cls2} points="24.423,1441.817 24.423,1060.083 104.454,940.708 103.492,1337.054 "/>
   <polygon style={styles.cls2} points="360.772,4085.921 1124.258,4085.921 1278.191,3904.112 1948.282,3904.112 2054.589,4033.341 1938.773,4147.622 418.411,4146.651 "/>
   <path style={styles.cls2} d="M2046.642 3792.619l218.513 0c-2.623,-4.675 -0.508,-1.158 -4.202,-5.701l-72.358 -89.621 -218.529 0c6.108,10.327 71.18,85.993 76.576,95.322l0 0zm-998.918 0l-30.91 -39.052c-5.767,-6.177 -7.123,-6.889 -10.55,-13.473l-1.828 0 -140.405 -0.025c-6.54,-0.035 -12.943,-1.174 -18.128,1.971 3.463,8.944 9.701,17.753 14.282,25.665 5.138,8.872 9.324,16.547 14.378,24.916l173.161 0 0 -0.002zm25.187 0l218.51 0 -41.431 -52.525 -1.595 -0.666 -216.925 -0.033c6.745,12.455 37.014,44.539 41.441,53.224l0 0zm240.972 0l218.542 0c-5.364,-9.21 -13.699,-16.686 -20.348,-25.739 -6.808,-9.262 -15.536,-17.265 -21.153,-26.786l-2.085 -0.699 -216.4 0c3.501,9.326 2.395,3.474 10.74,14.639l30.704 38.587 0 -0.002zm295.094 -54.472c-6.029,3.445 -81.19,1.248 -94.855,1.248l31.793 40.576c5.202,5.406 6.553,6.485 9.647,12.648l218.514 0c-17.595,-23.844 -40.523,-48.678 -57.018,-70.041l-19.511 -25.281 -47.923 0c-5.249,0.388 -4.773,0.905 -8.657,5.212 -11.242,12.466 -20.358,23.065 -31.99,35.638l0 0zm680.105 54.472l126.435 0 0 -95.322 -202.996 0c11.53,17.534 71.117,84.998 76.561,95.322l0 0zm-489.451 0l218.534 0c-6.37,-9.24 -10.813,-13.801 -18.707,-23.168l-19.188 -23.472c-3.284,-4.24 -5.331,-7.705 -8.792,-11.775l-29.851 -36.907 -218.513 0 76.519 95.322 -0.002 0z"/>
   <polygon style={styles.cls3} points="1315.066,250.049 1458.918,250.049 1458.918,303.633 1315.066,303.633 "/>
   <polygon style={styles.cls3} points="1154.159,250.049 1298.011,250.049 1298.011,303.633 1154.159,303.633 "/>
   <polygon style={styles.cls3} points="989.989,250.049 1133.84,250.049 1133.84,303.633 989.989,303.633 "/>
   <polygon style={styles.cls3} points="824.728,250.049 968.58,250.049 968.58,303.633 824.728,303.633 "/>
   <polygon style={styles.cls3} points="657.3,250.049 801.153,250.049 801.153,303.633 657.3,303.633 "/>
   <polygon style={styles.cls3} points="487.471,250.049 631.323,250.049 631.323,303.633 487.471,303.633 "/>
   <path style={styles.cls1} d="M1424.196 191.99l0 -101.449c0,-2.981 -1.455,-4.477 -4.333,-4.477 -3.065,0 -4.58,1.496 -4.58,4.477l0 101.449c0,2.986 1.515,4.477 4.58,4.477 2.878,0 4.333,-1.491 4.333,-4.477zm-37.511 -1.453l0 -95.948c0,-19.322 11.109,-28.984 33.362,-28.984 21.821,0 32.748,9.591 32.748,28.769l0 95.949c0,8.597 -3.062,15.236 -9.225,19.964 -6.127,4.724 -14.05,7.07 -23.706,7.07 -9.938,0 -17.954,-2.308 -24.049,-6.966 -6.099,-4.653 -9.13,-11.26 -9.13,-19.854l0 0z"/>
   <polygon style={styles.cls1} points="1381.514,216.078 1357.93,216.078 1357.93,194.336 1381.514,194.336 "/>
   <path style={styles.cls1} d="M1287.919 153.526l26.185 0 0 35.303c0,3.661 1.734,5.507 5.199,5.507 3.471,0 5.203,-1.846 5.203,-5.507l0 -34.419c0,-3.944 -0.868,-6.611 -2.602,-8.028 -1.702,-1.42 -4.921,-2.134 -9.66,-2.134l0 -16.836c8.175,0 12.262,-2.91 12.262,-8.741l0 -26.425c0,-2.984 -1.731,-4.475 -5.203,-4.475 -3.59,0 -5.382,1.491 -5.382,4.475l0 27.424 -25.261 0 0 -27.674c0,-17.579 10.649,-26.391 31.913,-26.391 8.513,0 15.938,2.131 22.283,6.359 6.346,4.229 9.504,10.901 9.504,20.068l0 20.498c0,11.934 -4.95,19.925 -14.826,24.012 10.12,3.407 15.197,12.608 15.197,27.633l0 22.13c0,20.712 -10.74,31.052 -32.188,31.052 -21.759,0 -32.624,-9.414 -32.624,-28.274l0 -35.557 0 0z"/>
   <polygon style={styles.cls1} points="1283.31,216.078 1259.722,216.078 1259.722,194.336 1283.31,194.336 "/>
   <path style={styles.cls1} d="M1255.728 174.12l-15.296 0 0 -15.53c0,-2.168 -0.877,-3.259 -2.638,-3.259 -1.766,0 -2.643,1.047 -2.643,3.134l0 57.613 -15.297 0 0 -71.612 15.297 0 0 2.402c2.035,-1.936 5.189,-2.904 9.467,-2.904 3.04,0 5.644,0.924 7.829,2.8 2.182,1.864 3.281,4.331 3.281,7.384l0 19.972 0 0z"/>
   <path style={styles.cls1} d="M1200.706 175.03l0 -16.44c0,-2.335 -0.861,-3.515 -2.563,-3.515 -1.747,0 -2.611,1.18 -2.611,3.515l0 16.44 5.174 0zm15.293 10.736l-20.467 0 0 16.586c0,2.42 0.864,3.622 2.611,3.622 1.702,0 2.563,-1.202 2.563,-3.622l0 -14.315 15.293 0 0 14.544c0,9.668 -5.975,14.503 -17.956,14.503 -11.865,0 -17.809,-5.032 -17.809,-15.109l0 -42.904c0,-10.077 5.944,-15.107 17.809,-15.107 11.981,0 17.956,4.835 17.956,14.5l0 27.302 0 0z"/>
   <polygon style={styles.cls1} points="1178.165,144.466 1166.845,216.078 1148.25,216.078 1137.121,144.466 1152.673,144.466 1157.86,186.778 1163.072,144.466 1178.165,144.466 "/>
   <polygon style={styles.cls5} points="213.35,359.712 2531.668,343.414 2681.942,514.394 2681.942,3684.499 2561.407,3828.416 830.563,3836.565 744.536,3668.681 182.39,3678.051 71.281,3531.741 71.281,2620.097 213.35,2483.766 "/>
   <polygon style={styles.cls1} points="464.3,3779.847 429.046,3749.901 509.29,3749.901 509.29,3941.085 464.3,4007.228 464.3,3779.847 "/>
   <polygon style={styles.cls1} points="675.189,3980.246 531.784,3980.246 531.784,3847.963 643.609,3847.963 643.609,3800.576 633.876,3783.793 545.627,3783.793 526.596,3749.902 668.269,3749.902 688.817,3786.426 688.817,3881.856 577.64,3881.856 577.64,3946.352 694.656,3946.352 675.189,3980.246 "/>
   
  </g>
 </g>
</svg>

    

  );
};

export default SVGMenu;
