import { useState, useRef, useEffect } from "react";
import SVGMenu from "../../svgs/SVGMenu";
import { Link } from "react-router-dom";

const MenuDropdown = () => {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!menuRef.current.contains(event.target)) {
        if (!showMenu) return;
        setShowMenu(false);
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showMenu, setShowMenu]);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
  }

  return (
    <div ref={menuRef} className="relative h-6 inline-block text-left">
      <button
        onClick={toggleMenu}
        className="button flex items-center space-x-3"
      >
        <p style={{ textShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' }}>Menu</p>

        <svg
          className={`w-4 h-4 transition-transform transform ${
            showMenu ? "rotate-180" : ""
          }`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {showMenu && (
       
          <div className="origin-top-right absolute top-12 right-0 w-52 rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none transition-opacity duration-300 drop-shadow-[0_5px_5px_rgba(0,0,0,0.4)]">
            <div className="menu w-full flex justify-center">
              <SVGMenu />
              <ul className="absolute w-[80%] top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 py-1 px-5 flex flex-col items-end gap-1">
                <Link
                  to="/"
                  onClick={closeMenu}
                  style={{ textShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' }}
                  className="text-gray-800 hover:bg-yellow-500 hover:font-semibold hover:text-white py-1 px-2 rounded-lg "
                >
                  Home
                </Link>
                
                <a
                       href="https://comunity.twelvezodiac.co/"
                       onClick={closeMenu}
                       className="text-gray-800 hover:bg-yellow-500 hover:font-semibold hover:text-white py-1 px-2 rounded-lg "
                     >
                       Community
                     </a>
                <a
                       href="https://foundation.twelvezodiac.co/"
                       onClick={closeMenu}
                       className="text-gray-800 hover:bg-yellow-500 hover:font-semibold hover:text-white py-1 px-2 rounded-lg "
                     >
                       Foundation
                     </a>
                <Link
                  to="/token"
                  onClick={closeMenu}
                  style={{ textShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' }}
                  className="text-gray-800 hover:bg-yellow-500 hover:font-semibold hover:text-white py-1 px-2 rounded-lg "
                >
                  Token
                </Link>
                <Link
                  to="/roadmap"
                  onClick={closeMenu}
                  style={{ textShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' }}
                  className="text-gray-800 hover:bg-yellow-500 hover:font-semibold hover:text-white py-1 px-2 rounded-lg "
                >
                  Roadmap
                </Link>
                <Link
                  to="/download"
                  onClick={closeMenu}
                  style={{ textShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' }}
                  className="text-gray-800 hover:bg-yellow-500 hover:font-semibold hover:text-white py-1 px-2 rounded-lg "
                >
                  Download
                </Link>
                <a
                            href="Pitch Deck.pdf"
                            target="pdf-frame"
                            onClick={closeMenu}
                            style={{ textShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' }}
                          className="text-gray-800 hover:bg-yellow-500 hover:font-semibold hover:text-white py-1 px-2 rounded-lg "
                        >
                          Document
                        </a>
              </ul>
            </div>
          </div>
       
      )}
    </div>
  );
};

export default MenuDropdown;
