import React from 'react';
import SVGTopFrame from '../svgs/SVGTopFrame';
import SVGComponents1 from '../svgs/SVGComponents1';
import SVGBorderDownside from '../svgs/SVGBorderDownside';
import '../styles/glitchText.css';

const CardCyber = ({children, label, text}) => {
  return (
    <div className="cards z-10 relative 2xl:w-1/3 xl:w-1/3 lg:w-1/3 w-full h-auto -translate-y-20 flex flex-col">
            <SVGTopFrame/>
            <div className="content-cards w-full h-auto bg-yello py-2 2xl:-translate-y-[2px] xl:-translate-y-[2px] lg:-translate-y-[2px] -translate-y-[10px]">
              <div className="top flex 2xl:flex-col xl:flex-col lg:flex-col flex-row p-1">
              
             
                <div className="card-img w-full  relative ">
                <SVGComponents1/>
                <div className="icon absolute top-[45%] -translate-y-[50%] left-[50%] -translate-x-[50%]">
                  {children}
                  
                  </div>
                  
                </div>
                <div className="title w-full bg-black flex items-center justify-center p-1 ">
                  <p className="text-white font-black text-3xl font-play glitch">
                    <span aria-hidden="true" className="glitchText">
                      {label}
                    </span>
                    {label}
                    <span aria-hidden="true" className="glitchText">
                    {label}
                    </span>
                  </p>
                </div>
              </div>

              <div className="content flex flex-wrap mt-2 p-2">
                <p className="text-gray-950 text-wrap text-justify 2xl:text-base md:text-base text-xs">
                  {text}
                </p>
              </div>
            </div>
            <div className="borderDown 2xl:-translate-y-[5px] xl:-translate-y-[5px] lg:-translate-y-[5px] md:-translate-y-[12px] -translate-y-[14px]">
            <SVGBorderDownside/>
            </div>
          </div>
  )
}

export default CardCyber
