
import './App.css';
import {Routes, Route} from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import Home from './pages/Home';
import Token from './pages/Token';
import Download from './pages/Download';
import Roadmap from './pages/Roadmap';
import Navigation from './components/navigation/Navigation';
import Footer from './components/footer/Footer';
import Privacy from './pages/Privacy';



function App() {

  return (
    
    <div className="App bg-[#080c2b] h-screen w-screen overflow-hidden">
      <ParallaxProvider scrollAxis='vertical'>
      <div className="main w-screen h-full ">
        <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/token" element={<Token />} />
        <Route path="/download" element={<Download />} />
        <Route path="/roadmap" element={<Roadmap />} />
        <Route path="/privacy" element={<Privacy />} />

      </Routes>
      <div className="foot relative">
      <Footer />
      </div>
      </div>
      </ParallaxProvider>
    </div>
  
  );
}

export default App;
