import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import SMN1 from "../asset/background/SS5a.png";
import SMN2 from "../asset/Summon2.png";
import BNB from "../asset/Logo/BNB.png";
import SVGTopBorder from "../components/svgs/SVGTopBorder";
import SVGBottomBorder from "../components/svgs/SVGBottomBorder";

const Download = () => {
  return (
    <section className="tokenomic my-44 flex flex-col justify-center items-center">
      <div className="wrapper 2xl:w-[50%] xl:w-[60%] lg:w-[65%] w-[90%] flex flex-col justify-between items-start gap-5">
        <section className="title w-full">
          <div className="wrapper w-full flex 2xl:flex-row xl:flex-row lg:flex-row flex-col justify-center items-center  ">
            <div className="left 2xl:w-[50%] xl:w-[50%] lg:w-[50%] w-full flex flex-col">
              <SVGTopBorder />
              <div className="content my-7 bg-[#00ffff]/10 p-5">
                <p className="text-white text-2xl font-semibold">
                  Try out the Demo Version!
                </p>
                <p className="text-white text-xl">We encourage you to try the demo version for Android.</p>
                <p className="text-white text-xl">In the Future Development, the Zodiac NFT can be used inside the Metaverse. Click the button bellow to Summon the Zodiac.</p>
                <div className="button my-5">
                <Link to="https://mainframe.twelvezodiac.co/marketplace" target="_blank" rel="noreferrer" className="px-5 py-2 transition-all duration-300 bg-[#00ffff]/10 hover:bg-[#00ffff]/50 text-white font-semibold mt-10"
                  >
                    Let's Summon
                 
                  </Link>
                  </div>
              </div>
              <SVGBottomBorder />
            </div>

            <div className="right flex justify-center items-center 2xl:w-[45%] xl:w-[45%] lg:w-[45%] w-[90%] ">
              <img
                  src={SMN2} alt=""
                  className="object-contain 2xl:h-[400px] xl:h-[400px] lg:h-[300px] h-[250px]  "
                />
            </div>
          </div>
        </section>

        <section className="flex justify-center items-center">
          <div className="1 flex flex-col justify-center items-center w-full">
          <SVGTopBorder />
            <div className="wrapper my-7 bg-[#00ffff]/10 p-5 flex 2xl:flex-row xl:flex-row lg:flex-row flex-col gap-10 justify-center items-center  ">
              <div
                id="download"
                className="howto my-20  w-full flex flex-col items-center justify-between scroll-smooth snap-center"
              >
                <div className="wrapper w-full flex 2xl:flex-row xl:flex-row lg:flex-row flex-col-reverse justify-center items-center">
                  <div className="left flex flex-col gap-3 2xl:w-2/4 xl:w-2/4 lg:w-2/4 w-[90%] items-start m-5">
                    <p className="text-white">Download file .apk here</p>
                    <div className="fileInfo flex flex-col justify-start items-start gap-3">
                      <p className="text-white text-end whitespace-normal   ">
                        File :
                        <span className="text-white text-end ml-2">12Metaverse_v19.apk</span>
                      </p>

                      <p className="text-white text-end whitespace-normal mb-2">
                      Version :
                        <span className="text-white text-end ml-2">19.0.0</span>
                      </p>
                      <button
                        as="a"
                        href="/resource/TwelveMetaverse_19.apk"
                        download="12Metaverse_v19.apk"
                        className="px-5 py-2 transition-all duration-300 bg-[#00ffff]/10 hover:bg-[#00ffff]/50 text-white font-semibold"
                      >
                       
                         
                          <p className="text-sm text-white">DOWNLOAD</p>
                        
                      </button>
                    </div>
                  </div>
                  <div className="right 2xl:w-1/2 xl:w-1/2 lg:w-1/2 w-[90%] ">
                    <img src={SMN1} alt="" className="" />
                  </div>
                </div>
                <div className="version-release w-full mt-5 ">
                  <p className="text-white text-start">Version History</p>
                  <div className="versions grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 grid-cols-1 text-white gap-5 my-5">
                    <blockquote>
                      <div className="one p-3 text-start">
                        <p>12Metaverse.apk</p>
                        <p>Ver.16.0.1</p>
                        <p className="text-sm">- Fix Character</p>
                        <p className="text-sm">- Optimize Pets COllection</p>
                        <p className="text-sm">- SDK Multiplayer</p>
                      </div>
                    </blockquote>
                    <blockquote>
                      <div className="two p-3 text-start">
                        <p>12Metaverse.apk</p>
                        <p>Ver.17.0.0</p>
                        <p className="text-sm">- Fix Crash On Wallet Connect</p>
                      </div>
                    </blockquote>
                    <blockquote>
                      <div className="three p-3 text-start">
                        <p>12Metaverse.apk</p>
                        <p>Ver.18.0.1</p>
                        <p className="text-sm">- Wallet Connect </p>
                        <p className="text-sm">- Pets System Test</p>
                      </div>
                    </blockquote>
                    <blockquote>
                      <div className="four p-3 text-start">
                        <p>12Metaverse.apk</p>
                        <p>Ver.19.0.1</p>
                        <p className="text-sm">- Bug tests and fixes </p>
                      </div>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
            <SVGBottomBorder />
          </div>
        </section>
        <div className="section flex justify-center items-center">
          <div className="1 flex flex-col justify-center items-center 2xl:w-[65%] xl:w-[65%] lg:w-[65%] w-[90%]">
            <div className="wrapper flex flex-col gap-10 justify-center items-center my-10 w-full px-3">
              {/* <CommentsSection/> */}
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center items-center mx-auto pt-8"></div>
      </div>
    </section>
  );
};

export default Download;
