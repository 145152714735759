import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { REACT_APP_API_ENDPOINT } from '../utility/config';

const useGetPrice = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    let intervalId;

    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.twelvezodiac.co/market/price`);
        setData(response?.data?.coinmarketcap?.USD.price);

        // If data is set, clear the interval
        if (response?.data?.data?.marketcap?.USD) {
          clearInterval(intervalId);
        }
      } catch (err) {
        setError(err);
      }
    };

    fetchData(); // Fetch data immediately after mounting
    intervalId = setInterval(fetchData, 30000); // Then fetch data every 30 seconds

    return () => clearInterval(intervalId); // Clean up interval on unmount
  }, []); // Empty dependency array means this effect runs once on mount and clean up on unmount

  return { data, error };
};

export default useGetPrice;