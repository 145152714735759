import React from 'react';
import CardCyber from "../components/card/CardCyber";
import { BiSolidJoystick } from "react-icons/bi";
import { FaDatabase } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";

const Featured = () => {
  return (
    <section className="featured flex justify-center items-center bg-[#000730]">
        <div className="wrapper 2xl:w-[50%] xl:w-[60%] lg:w-[65%] w-[90%] flex 2xl:flex-row xl:flex-row lg:flex-row flex-col justify-between items-start gap-5">
        <CardCyber label="earn" text="With just a few simple steps, the player's squad will
                  automatically stake, even when the holders are offline. The
                  holders will still receive the usual rewards. Possessing a
                  compelling storyline with thousands of diverse NFT pet
                  characters, later on, Twelve will open a meaningful journey of
                  discovery and combat.">
          <FaDatabase className="2xl:text-[100px] xl:text-[80px] lg:text-[70px] md:text-[70px] text-[60px] text-gray-800 -translate-x-5" />
          </CardCyber>
          <CardCyber label="play" text="Enjoy mini-games, Easter egg hunts, and many more. Thus, giving you an enjoyable experience in the TWELVE Metaverse.">
          <BiSolidJoystick className="2xl:text-[100px] xl:text-[80px] lg:text-[70px] md:text-[70px] text-[60px] text-gray-800 -translate-x-5" />
          </CardCyber>
          
          <CardCyber label="community" text="Make friends and sharpen your leadership skills among friends
                  around the world. Take a selfie, show off your pets, and hang
                  out together in the communal area. Enjoy our family-based
                  metaverse.">
          <FaPeopleGroup className="2xl:text-[100px] xl:text-[80px] lg:text-[70px] md:text-[70px] text-[60px] text-gray-800 -translate-x-5" />
          </CardCyber>
          
        </div>
      </section>
  )
}

export default Featured
