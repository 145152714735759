import React from 'react'

const SVGTopFrame = () => {
    const styles = {
        cls1: {
          fill: "#D0B050",
        },
        cls2: {
          fill: "#D0B050",
        },
        cls3: {
          fill: "none",
          stroke: "#D0B050",
          strokeMiterlimit: 10,
          strokeWidth: "8px",
        },
        cls4: {
          fill: "#D0B050",
          strokeMiterlimit: 10,
          strokeWidth: "4px",
        }
      };
  return (
    


<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 694.94 93.82" style={{maxWidth: '100%', height: 'auto'}}>
      <g id="Layer_x0020_1">
        <path style={styles.cls2} d="M27.02 69.6l-11.58 -19.52 42.08 0 12.78 -22.31 38.52 0 24.34 41.83 80.38 0 11.73 -19.52 175 0 10.67 14.85 127.07 0 7.85 -14.85 114.25 0 10.96 19.52 23.84 0 0.01 24.22 -694.94 0 0.13 -24.22 26.89 0zm60.42 -69.6l228.45 0 6.31 11.64 -95.99 0 -18.72 30.43 -69.23 0 -20.97 -36.03 -33.12 0 3.27 -6.04zm325.47 50.08l122.74 0 -4.42 9.47 -111.47 0 -6.85 -9.47zm-15.04 19.52l-122.74 0 4.42 -9.47 111.47 0 6.85 9.47z"/>
      </g>
    </svg>

  )
}

export default SVGTopFrame
