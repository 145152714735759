import React from "react";
import StripesBtn from "../components/button/StripesBtn/StripesBtn";

const Roadmap = () => {
  return (
    <section className="tokenomic my-44 flex flex-col justify-center items-center">
      <div className="wrapper 2xl:w-[50%] xl:w-[60%] lg:w-[65%] w-[90%] flex flex-col justify-between items-start gap-5">
        <StripesBtn isButton={false}>ROADMAP</StripesBtn>
        <div className="content-container w-full">
          <div className="roadmap-griding grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 grid-cols-1 gap-8">
            <div className=" roadmaps-border q1 p-5 gap-2 text-start flex flex-col text-sm text-[#00ffff] text-md border border-[#00ffff] rounded-t-lg backdrop-blur-md">
              <div className="title flex flex-col gap-2 mb-3">
                <p className="tittleQ p-4 text-4xl rounded-md font-black bg-[#00ffff] text-sky-700 font-['Play']">
                  Q1
                </p>

                <p className="font-bold font-['Play']">Milestone</p>
              </div>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Create Game design document
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Develop a prototype for the game
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Business analysis
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                $Twelve Charity
              </p>
            </div>

            <div className="roadmaps-border 2a p-5 gap-2 text-start flex flex-col text-sm text-[#00ffff] text-md border border-[#00ffff] rounded-t-lg backdrop-blur-md">
              <div className="title flex flex-col gap-2 mb-3">
                <p className="tittleQ p-4 text-4xl rounded-md font-black bg-[#00ffff] text-sky-700 font-['Play']">
                  Q2a
                </p>

                <p className="font-bold font-['Play']">Preparation Phase</p>
              </div>
              <p className="font-bold text-md drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Token Launch :{" "}
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Token Launch TWELVE$ press release
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Seed sale, Private sale, Public Sale
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Exchanger Launch followed by multiple Exchanger{" "}
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Online and Offline Launching in 5 Countries
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Release NFT Twelve Zodiac
              </p>

              <p className="font-bold text-md drop-shadow-[1px_1px_5px_rgb(51,255,255)] mt-5 font-['Play']">
                Metaverse :
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Avatar and Zodiac Pets Optimizations
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Mobile App Integration and Optimization
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Environment Design
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Multiple Infrastructur and Back end
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Basic UI
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                NPC and AI Optimization
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Blockchain Integration V1
              </p>
            </div>

            <div className="roadmaps-border 2a p-5 gap-2 text-start flex flex-col text-sm text-[#00ffff] text-md border border-[#00ffff] rounded-t-lg backdrop-blur-md">
              <div className="title flex flex-col gap-2 mb-3">
                <p className="tittleQ p-4 text-4xl rounded-md font-black bg-[#00ffff] text-sky-700 font-['Play']">
                  Q2b
                </p>

                <p className="font-bold font-['Play']">Metaverse Beta Launch</p>
              </div>
              <p className="font-bold text-md drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Token Launch :{" "}
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Marketing offline and online gathering
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Website v.2{" "}
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Trust Wallet and Metamask support
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Decentralized Exchange (pancakeswap pool)
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                NFT marketplace v.1
              </p>

              <p className="font-bold text-md drop-shadow-[1px_1px_5px_rgb(51,255,255)] mt-5 font-['Play']">
                Metaverse :
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Release the Metaverse landing page v.1 (beta)
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Multiplayer Gameplay
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Blockchain Integration v2
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Social Feature (chat and voices)
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Login and Player Database
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Server Load Balancing
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                PC and web version
              </p>
            </div>

            <div className="roadmaps-border q3 p-5 gap-2 text-start flex flex-col text-sm text-[#00ffff] text-md border border-[#00ffff] rounded-t-lg backdrop-blur-md">
              <div className="title flex flex-col gap-2 mb-3">
                <p className="tittleQ p-4 text-4xl rounded-md font-black bg-[#00ffff] text-sky-700 font-['Play']">
                  Q3
                </p>

                <p className="font-bold font-['Play']">
                  Public Launch and preparation of mini Games live
                </p>
              </div>

              <p className="font-bold text-md drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Token Launch :{" "}
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Website V.3
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Launching metaverse online
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                NFT Marketplace v.2
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Audit Smart contract by Certik
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Global campaign{" "}
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Optimize Gas Fee and Burn Mechanism
              </p>

              <p className="font-bold text-md drop-shadow-[1px_1px_5px_rgb(51,255,255)] mt-5 font-['Play']">
                Metaverse :
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Avatar Skin Add-on
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Rarity NFT live
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Easter egg hunting games
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                More game announcement
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Friend System added
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Messages and mail in game
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                UI/UX optimization
              </p>
              <p className="drop-shadow-[1px_1px_5px_rgb(51,255,255)] font-['Play']">
                Grandprize Added
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
