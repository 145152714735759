import React from "react";
import StripesBtn from "../components/button/StripesBtn/StripesBtn";
import tw12 from "../asset/background/zodiacDragons.png";
import "./About.css";
import SVGFrame3 from "../components/svgs/SVGFrame3";

const About = () => {
  return (
    <section className="about w-full flex flex-col justify-center items-center bg-[#000730]">
      <div className="wrapper my-10 2xl:w-[50%] xl:w-[60%] lg:w-[65%] w-[90%] flex flex-col justify-between items-start">
        <StripesBtn isButton={false}>About Metaverse</StripesBtn>
      </div>
      <span className="containBorder w-full h-7 bg-white 2xl:translate-y-[2px] xl:translate-y-[2px] lg:translate-y-[2px] translate-y-[15px]" />
      <div className="contain-About bg-gradient-to-b from-[#D0B050] to-black/80 w-full flex justify-center items-center">
        <div className="wrapper flex flex-col justify-between items-start gap-5">
          <div className="about-content w-full flex 2xl:flex-row xl:flex-row flex-col items-center 2xl:gap-10 xl:gap-10 lg:gap-10 gap-0">
            <div className="left 2xl:w-1/2 xl:w-1/2 lg:w-1/2 w-full flex flex-col gap-4 2xl:py-20 xl:py-14 py-5 2xl:pl-72 xl:pl-60 lg:px-5 md:px-44 px-5 ">
              <div className="aboutText relative  w-full  flex flex-col justify-center items-center gap-6 p-8 border border-black">
                <div className="group absolute w-full h-full ">
                  <svg
                    height="40"
                    width="40"
                    viewBox="0 0 20 20"
                    preserveAspectRatio="none"
                    className="absolute -top-3 -left-3 group-hover:-top-6 group-hover:-left-6 transition-all duration-300"
                  >
                    <polyline
                      points="0,10 0,0 10,0"
                      style={{
                        fill: "none",
                        stroke: "black",
                        strokeWidth: "4",
                      }}
                    />
                  </svg>
                  <svg
                    height="40"
                    width="40"
                    viewBox="0 0 20 20"
                    preserveAspectRatio="none"
                    className="absolute -top-3 -right-3 group-hover:-top-6 group-hover:-right-6 transition-all duration-300"
                  >
                    <polyline
                      points="10,0 20,0 20,10"
                      style={{
                        fill: "none",
                        stroke: "black",
                        strokeWidth: "4",
                      }}
                    />
                  </svg>
                  <svg
                    height="40"
                    width="40"
                    viewBox="0 0 20 20"
                    preserveAspectRatio="none"
                    className="absolute -bottom-3 -left-3 group-hover:-bottom-6 group-hover:-left-6 transition-all duration-300"
                  >
                    <polyline
                      points="0,10 0,20 10,20"
                      style={{
                        fill: "none",
                        stroke: "black",
                        strokeWidth: "4",
                      }}
                    />
                  </svg>
                  <svg
                    height="40"
                    width="40"
                    viewBox="0 0 20 20"
                    preserveAspectRatio="none"
                    className="absolute -bottom-3 -right-3 group-hover:-bottom-6 group-hover:-right-6 transition-all duration-300"
                  >
                    <polyline
                      points="10,20 20,20 20,10"
                      style={{
                        fill: "none",
                        stroke: "black",
                        strokeWidth: "4",
                      }}
                    />
                  </svg>
                </div>
                <p className="text-gray-800 text-wrap text-base text-justify">
                 
                  <span className="font-black text-3xl px-2 py-1 mr-2">
                    TWELVE
                  </span>
                  is the City of Dream made for urban city lifestyle with
                  pedestrian friendly and each building have different interact
                  function. Token holders will be known as twelve mania. Twelve
                  mania will be able to experience world-class games and
                  metaverse with stunning visual and also interact and build
                  network community.
                </p>
                <p className="text-gray-800 text-wrap text-base text-justify">
                  Create your own Avatar and socialize, be whatever you want, you can
                  play to earn and keep building your network community online
                  and offline around the world.
                </p>
                <p className="text-gray-800 text-wrap text-base text-justify">
                  Over the years, we've been thinking about how to create a
                  perfect environment for our Metaverse which can combine
                  Communal area for community, Mini casino games, Grand
                  prize and reward. Billboard and Advertising, Art auction, Egg
                  Hunting and many more.
                </p>
              </div>
            </div>
            <div className="right 2xl:w-1/2 xl:w-1/2 lg:w-1/2 w-full">
            
            <div className="glitchings">  
            <img src={tw12} alt="" className="glitchings object-cover" />
    </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
