import { useState, useEffect, useRef } from "react";
import { Spin as Hamburger } from "hamburger-react";
import LogoDesktop from "../../asset/Logo/twelve-logo.png";
import LogoMobile from "../../asset/Logo/Twelve.png";
// import SelectLanguage from "../button/Dropdown/SelectLanguage";
import MenuDropdown from "../button/Dropdown/MenuDropdown";
import SVGMenu from "../svgs/SVGMenu";
import useGetPrice from "../../hooks/useGetPrice";
import { Link } from "react-router-dom";
import numeral from "numeral";
import "../styles/Navigation.css";

const Navigation = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [getPrice, setGetPrice] = useState();
  const [showModal, setShowModal] = useState(false);
  // const { price } = useFetchPrice();
  const modalRef = useRef();
  const videoRef = useRef(null);
  const { data, error } = useGetPrice();

  // console.log(data);

  const closeNav = () => {
    setNavOpen(false);
  };

  useEffect(() => {
    setShowModal(true);
  }, []);

  const handleScrollTo = (e) => {
    e.preventDefault();
    const targetElement = document.querySelector("#announcements");
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
    setShowModal(false);
  };

  return (
    <div className="nav fixed top-0 left-0 z-[200] w-full h-24 flex items-center justify-center py-5">
      <div className="wrapper 2xl:w-[50%] xl:w-[60%] lg:w-[65%] w-[90%] bg-yellow-200/40 border-t-2 border-l-2 border-gray-200 shadow-md shadow-gray-400 backdrop-blur-md rounded-xl flex justify-between items-center px-3 2xl:py-4 xl:py-4 lg:py-4 py-2">
        <div className="left 2xl:block xl:block lg:block hidden">
          <Link to="/">
            <img src={LogoDesktop} alt="" className="w-auto h-6" />
          </Link>
        </div>
        <div className="middle flex">
          <span className="inline-flex gap-3 items-center font-semibold 2xl:text-xl xl:text-xl lg:text-xl text-sm text-white">
            <img src={LogoMobile} alt="" className="h-6 w-auto" />
            <p
              className="2xl:text-lg xl:text-lg lg:text-lg text-base"
              style={{ textShadow: "0px 0px 4px rgba(0, 0, 0, 1)" }}
            >
              {data ? numeral(data).format("0,0.00") : "0.00"}
            </p>
          </span>
        </div>
        <div className="right relative">
          <ul className="flex items-center justify-center gap-5 ">
            <a
              href="https://mainframe.twelvezodiac.co"
              className=" text-white cursor-pointer "
              style={{ textShadow: "0px 0px 4px rgba(0, 0, 0, 1)" }}
            >
              Start App
            </a>
            <a
              href="https://comunity.twelvezodiac.co/"
              className=" text-white cursor-pointer 2xl:block xl:block lg:block hidden"
              style={{ textShadow: "0px 0px 4px rgba(0, 0, 0, 1)" }}
            >
              Community
            </a>
            <a
              href="https://foundation.twelvezodiac.co/"
              className=" text-white cursor-pointer 2xl:block xl:block lg:block hidden"
              style={{ textShadow: "0px 0px 4px rgba(0, 0, 0, 1)" }}
            >
              Foundation
            </a>
            <li className=" text-white cursor-pointer 2xl:block xl:block lg:block hidden">
              <MenuDropdown />
            </li>
            {/* <li className="text-white cursor-pointer">
              {" "}
              <SelectLanguage />{" "}
            </li> */}
            <li className="hamburger 2xl:hidden xl:hidden lg:hidden block">
              <Hamburger
                toggled={navOpen}
                toggle={setNavOpen}
                size={24}
                color="#fff"
                className="cursor-pointer pointer-events-auto"
              />
            </li>
          </ul>
          {navOpen && (
            <div className="pointer-events-none origin-top-right absolute top-20 right-0 w-52 h-72 flex items-center justify-center rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none transition-opacity duration-300">
              <div className="menu relative w-full flex justify-center items-center">
                <SVGMenu />
                <ul
                  ref={modalRef}
                  className="absolute w-[80%] px-5 flex flex-col justify-center items-end gap-1 pointer-events-auto"
                >
                  <Link
                    to="/"
                    onClick={closeNav}
                    className="text-gray-800 hover:bg-yellow-500 hover:font-semibold hover:text-white py-1 px-2 rounded-lg "
                  >
                    Home
                  </Link>
                  <a
                    href="https://comunity.twelvezodiac.co/"
                    onClick={closeNav}
                    className="text-gray-800 hover:bg-yellow-500 hover:font-semibold hover:text-white py-1 px-2 rounded-lg "
                  >
                    Community
                  </a>
                  <a
                    href="https://foundation.twelvezodiac.co/"
                    onClick={closeNav}
                    className="text-gray-800 hover:bg-yellow-500 hover:font-semibold hover:text-white py-1 px-2 rounded-lg "
                  >
                    Foundation
                  </a>
                  <Link
                    to="/token"
                    onClick={closeNav}
                    className="text-gray-800 hover:bg-yellow-500 hover:font-semibold hover:text-white py-1 px-2 rounded-lg "
                  >
                    Token
                  </Link>
                  <Link
                    to="/roadmap"
                    onClick={closeNav}
                    className="text-gray-800 hover:bg-yellow-500 hover:font-semibold hover:text-white py-1 px-2 rounded-lg "
                  >
                    Roadmap
                  </Link>
                  <Link
                    to="/download"
                    onClick={closeNav}
                    className="text-gray-800 hover:bg-yellow-500 hover:font-semibold hover:text-white py-1 px-2 rounded-lg "
                  >
                    Download
                  </Link>
                  <a
                    href="Pitch Deck.pdf"
                    target="pdf-frame"
                    onClick={closeNav}
                    className="text-gray-800 hover:bg-yellow-500 hover:font-semibold hover:text-white py-1 px-2 rounded-lg "
                  >
                    Document
                  </a>
                </ul>
              </div>
            </div>
          )}
        </div>
        {/* <button
          onClick={handleScrollTo}
          className={`modal-announcement absolute top-32 right-0 bg-white/90 rounded-xl px-5 py-2 ${
            showModal ? "slide-in" : "slide-out"
          }`}
        >
          <div className="modal-announcement-content ">
            <p className="font-black text-xl text-red-500">
              TWELVE ANNOUNCEMENT
            </p>
            <p>
              <b>Binary12</b> is NOT part of the Twelve program
            </p>
            <p className="text-blue-400">Read more...</p>
          </div>

        </button> */}
      </div>
    </div>
  );
};

export default Navigation;
