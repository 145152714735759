import React from 'react'

const SVGBorderDownside = () => {
    const styles = {
        cls1: {
          fill: "#D0B050",
        },
        cls2: {
          fill: "#D0B050",
        },
        cls3: {
          fill: "none",
          stroke: "#D0B050",
          strokeMiterlimit: 10,
          strokeWidth: "8px",
        },
        cls4: {
          fill: "#D0B050",
          strokeMiterlimit: 10,
          strokeWidth: "4px",
        }
      };
  return (
    
<svg xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 3904.23 133.26"
>
 <g>
  <polygon style={styles.cls2} points="3904.23,99.83 3536.24,99.83 3508.2,30.81 3470.67,133.26 3428.62,133.26 3412.8,99.83 2535.77,99.83 2520.31,52.32 2506.33,99.83 2312.4,99.83 2292.77,30.64 2116.88,30.64 2091.1,99.83 1836.34,99.83 1817.99,52.32 1797.67,99.83 645.57,99.83 624.78,133.26 569.54,133.26 520.24,30.81 483.41,99.83 -0,99.83 -0,0 2108.54,0 2299.12,0 3904.23,0 "/>
 </g>
</svg>
  )
}

export default SVGBorderDownside
