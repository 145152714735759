import React from 'react'
import "../../styles/StripesBtn.css"

const StripesBtn = ({children, isButton = true, onClick}) => {
    const className = isButton ? "stripe-btn" : "stripe-btn no-pointer-events stripes-auto-animate";

  return (
    <button onClick={onClick} className={className}>
      <span className="label text-white font-black font-play 2xl:text-3xl xl:text-3xl lg:text-3xl text-lg px-6 py-3">
        {children}
      </span>
      <div className="corners">
        <i></i>
        <i></i>
        <i></i>
        <i></i>
      </div>
      <div className="stripes">
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
      </div>
    </button>
  )
}

export default StripesBtn