import React from 'react';
import CornersBtn from "../components/button/CornersBtn/CornersBtn";
import {
    FaTelegram,
    FaXTwitter,
    FaInstagram,
    FaYoutube,
  } from "react-icons/fa6";
  import { SiCoinmarketcap } from "react-icons/si";
import SVGBorderDownside from '../components/svgs/SVGBorderDownside';
  
const Community = () => {
  return (
    <section className="community flex flex-col items-center">
    {/* <div className="wrapper 2xl:w-[50%] xl:w-[60%] lg:w-[65%] w-[90%] flex flex-col justify-between items-start gap-5">
    
    </div> */}
    <span className="b123 w-full h-4 bg-white 2xl:translate-y-[2px] xl:translate-y-[2px] lg:translate-y-[2px] translate-y-[5px]"/>
    <div className="contact-content w-full flex flex-col justify-center items-center bg-yello py-10">
      <div className="title flex items-center ">
   
        <div className="text flex flex-col items-center py-10">
          <p className="text-black font-black 2xl:text-3xl xl:text-3xl lg:text-3xl text-xl font-play glitch">
            <span aria-hidden="true" className="glitchText">
              Join the Community
            </span>
            Join the Community
            <span aria-hidden="true" className="glitchText">
              Join the Community
            </span>
          </p>
          <p className="text-black font-black 2xl:text-base xl:text-base lg:text-base text-xs">
            Check our Social Media for more Info
          </p>
        </div>
      
      </div>

      <div className="icons w-full flex items-center justify-center text-black text-3xl">
        <div className="wrapIcons flex items-center justify-center flex-wrap">
        <CornersBtn href="https://t.me/twelvezodiacofficial" title="Telegram - Twelve Zodiac Official"><FaTelegram/></CornersBtn>
        <CornersBtn href="https://t.me/twelvezodiac" title="Telegram - Twelve Zodiac Official"><FaTelegram/></CornersBtn>
        <CornersBtn href="https://twitter.com/TwelveZodiac12" title="Telegram - Twelve Zodiac Official"><FaXTwitter/></CornersBtn>
        <CornersBtn href="https://www.instagram.com/twelvezodiac12" title="Telegram - Twelve Zodiac Official"><FaInstagram/></CornersBtn>
        <CornersBtn href="https://youtube.com/@TwelveZodiac" title="Telegram - Twelve Zodiac Official"><FaYoutube/></CornersBtn>
        <CornersBtn href="https://coinmarketcap.com/currencies/twelve-zodiac/" title="Telegram - Twelve Zodiac Official"><SiCoinmarketcap/></CornersBtn>
        </div>
      </div>
      <div className="email my-10 w-full flex flex-col justify-center items-center">
      <p>For more Information and Technical Support</p>
      <p className="font-semibold">Support@twelvezodiac.co</p>  
      </div>
    </div>
    <div className="borderDown w-full h-auto 2xl:-translate-y-[2px] xl:-translate-y-[5px] lg:-translate-y-[5px] md:-translate-y-[12px] -translate-y-[14px]">
            <SVGBorderDownside/>
            </div>
  </section>
  )
}

export default Community
