import React from "react";
import "../../styles/CornersBtn.css";

function CornersBtn({ children, href }) {
  return (
    <div className="buttons w-32 h-32 flex justify-center items-center">
      <a href={href} className="cb-btn p-2 text-3xl transition-all duration-300 hover:p-1">
        <div className="icon border-black border p-5">{children}</div>
        <div className="corners">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </div>
      </a>
    </div>
  );
}

export default CornersBtn;
