import React, {useEffect, useRef} from 'react';
import SVGFrame1 from "../components/svgs/SVGFrame1";
import StripesBtn from "../components/button/StripesBtn/StripesBtn";
import DP from "../asset/background/dragonPlayer.png";
import RP from "../asset/background/ratPlayer.png";
import game1 from "../asset/background/SS3.webp";
import game2 from "../asset/background/SS1.webp";
import './warp.css'

const Game = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    let canvas = canvasRef.current;
    let ctx = canvas.getContext("2d");
    let stars = [];
    let numStars = 1900;

    function initializeStars() {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      for(let i = 0; i < numStars; i++){
        stars.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          z: Math.random() * canvas.width,
        });
      }
    }

    function moveStars() {
      for(let i = 0; i < numStars; i++){
        stars[i].z--;
        if(stars[i].z <= 0){
          stars[i].z = canvas.width;
        }
      }
    }

    function drawStars() {
      ctx.fillStyle = "rgba(0,10,20,1)";
      ctx.fillRect(0,0, canvas.width, canvas.height);

      for(let i = 0; i < numStars; i++){
        let star = stars[i];
        let pixelX = (star.x - canvas.width / 2) * (canvas.width / star.z);
        pixelX += canvas.width / 2;
        let pixelY = (star.y - canvas.height / 2) * (canvas.width / star.z);
        pixelY += canvas.height / 2;
        let pixelRadius = 1 * (canvas.width / star.z);
        
        ctx.fillStyle = "rgba(209, 255, 255, 1)";
        ctx.fillRect(pixelX, pixelY, pixelRadius, pixelRadius);
      }
    }

    function executeFrame() {
      requestAnimationFrame(executeFrame);
      moveStars();
      drawStars();
    }

    initializeStars();
    executeFrame();
  }, []);

  return (
    <section className="game relative flex flex-col justify-center items-center py-10">
         <div id='w'>
  <canvas id="space" ref={canvasRef}></canvas>

</div>
        <div className="wrapper my-10 2xl:w-[50%] xl:w-[60%] lg:w-[65%] w-[90%] flex flex-col justify-between items-start gap-5">
          <StripesBtn isButton={false}>The Metaverse</StripesBtn>
          <div className="game-content relative w-full flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row flex-col-reverse items-center gap-10">
            <div className="left 2xl:w-1/2 xl:w-1/2 lg:w-1/2 w-full ">
              <img src={DP} alt="" className="float w-full " />
            </div>

            <div className="right 2xl:w-1/2 xl:w-1/2 lg:w-1/2 w-full flex flex-col">
              <div className="w-full relative">
                <SVGFrame1 />
                <div className="left absolute top-[50%] -translate-y-[50%] left-[47%] -translate-x-[50%] flex flex-col 2xl:w-[65%] xl:w-[65%] lg:w-[65%] w-[70%] h-auto gap-3">
                  <p className="text-gray-900 2xl:text-xl xl:text-xl lg:text-sm text-sm text-wrap text-justify font-play">
                    To Play the Game, you'll need to summon your own Zodiac and
                    start exploring the Twelve Metaverse
                  </p>
                  <img src={game1} alt="" className="rounded-xl" />
                </div>
              </div>
            </div>
          </div>
          <div className="game-content relative w-full flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row flex-col items-center gap-10">
            <div className="left 2xl:w-1/2 xl:w-1/2 lg:w-1/2 w-full flex flex-col">
              <div className="w-full relative">
              <SVGFrame1 />
                <div className="left left absolute top-[50%] -translate-y-[50%] left-[47%] -translate-x-[50%] flex flex-col 2xl:w-[65%] xl:w-[65%] lg:w-[65%] w-[70%] h-auto gap-3">
                  <p className="text-gray-900 2xl:text-xl xl:text-xl lg:text-sm text-sm text-wrap text-justify font-play">
                    You can earn more $Twelve with the Zodiac. Don't waste more
                    time, go to marketplace right away.
                  </p>
                  <img src={game2} alt="" className="rounded-xl" />
                </div>
              </div>
            </div>
            <div className="right 2xl:w-1/2 xl:w-1/2 lg:w-1/2 w-full relative">
              <img src={RP} alt="" className="" />
              <span className="shine"></span>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Game
