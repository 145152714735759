import React, { useEffect, useState, useCallback, useRef } from "react";
import StripesBtn from "../components/button/StripesBtn/StripesBtn";
import SVGTopBorder from '../components/svgs/SVGTopBorder';
import SVGBottomBorder from '../components/svgs/SVGBottomBorder';
import axios from "axios";
import { REACT_APP_API_ENDPOINT } from "../utility/config";
import numeral from 'numeral';

const GeneralInfo = () => {
    const [error, setError] = useState(null);
    const [info, setInfo] = useState({
        toBurn: 0,
        totalStaking: 0,
        burned: 0,
        circular: 0,
      });

    const getInfo = useCallback(async () => {
        try {
          const response = await axios.get(
            REACT_APP_API_ENDPOINT + "/informations"
          );
          const data = response.data?.data;
    
          setInfo({
            toBurn: data?.ready_to_burn,
            totalEarn: data?.total_staking,
            burned: data?.token_burned,
            circular: data?.circular_supply,
          }); // Assuming the data you want is in response.data
        } catch (err) {
          setError(err);
          console.log(error)
        }
      }, []); // No dependencies, so it won't change between renders
    
      useEffect(() => {
        getInfo();
      }, []); // Empty dependency array to run only once

  return (
    <section className="partnership relative my-10 flex justify-center items-center text-white">
    <div className="wrapper 2xl:w-[50%] xl:w-[60%] lg:w-[65%] w-[90%] flex flex-col justify-between items-start gap-5">
      <StripesBtn isButton={false}>Token Information</StripesBtn>
      <div className="about-content relative w-full flex flex-col">
      <span className="w-full h-2 bg-[#00ffff]"/>
              <div className="content my-3 bg-[#00ffff]/10 p-5">
                <div className="content grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-2 grid-cols-1 w-full gap-5 ">
                    <div className="circularSupplyInfo place-self-center bg-[#00FFFF] border border-[#00FFFF] w-full">
                      <div className="boxInfo  place-self-center bg-black/95 p-2 border border-[#00FFFF] w-full" style={{clipPath : `polygon(0% 0%, 85% 0%, 100% 20%, 100% 100%, 0% 100%)`}}>
                         <p className="text-[#00ffff] text-lg font-semibold inline-flex items-center">Circular Supply <span className="blinkDot ml-2 text-2xl animate-ping">|</span>  </p>
                         <div className="box h-20 w-full border border-[#00ffff] bg-[#00ffff]/10 mt-4 flex justify-center items-center">
                    <p className="font-bold">{info.circular !== undefined
                      ? numeral(info.circular).format("0,0.0000")
                      : "0.0000"}</p>
                    </div>
                    </div>
                    </div>
                    <div className="EarnInfo place-self-center bg-[#00FFFF] border border-[#00FFFF] w-full">
                      <div className="boxInfo  place-self-center bg-black/95 p-2 border border-[#00FFFF] w-full" style={{clipPath : `polygon(0% 0%, 85% 0%, 100% 20%, 100% 100%, 0% 100%)`}}>
                         <p className="text-[#00ffff] text-lg font-semibold inline-flex items-center">Total Earn  <span className="blinkDot ml-2 text-2xl animate-ping">|</span></p>
                    <div className="box h-20 w-full border border-[#00ffff] bg-[#00ffff]/10 mt-4 flex justify-center items-center">
                    <p className="font-bold">{info.totalEarn !== undefined
                      ? numeral(info.totalEarn).format("0,0.0000")
                      : "0.0000"}</p>
                    </div>
                    </div>
                    </div>
                    <div className="toBurnInfo place-self-center bg-[#00FFFF] border border-[#00FFFF] w-full">
                      <div className="boxInfo  place-self-center bg-black/95 p-2 border border-[#00FFFF] w-full" style={{clipPath : `polygon(0% 0%, 85% 0%, 100% 20%, 100% 100%, 0% 100%)`}}>
                         <p className="text-yello text-lg font-semibold inline-flex items-center">Ready to Burn <span className="blinkDot ml-2 text-2xl animate-ping">|</span></p>
                         <div className="box h-20 w-full border border-[#00ffff] bg-yello/80 animate-pulse mt-4 flex justify-center items-center">
                    <p className="font-bold">{info.toBurn !== undefined
                      ? numeral(info.toBurn).format("0,0.0000")
                      : "0.0000"}</p>
                    </div>
                    </div>
                    </div>
                    <div className="burnInfo place-self-center bg-[#00FFFF] border border-[#00FFFF] w-full">
                      <div className="boxInfo  place-self-center bg-black/95 p-2 border border-[#00FFFF] w-full" style={{clipPath : `polygon(0% 0%, 85% 0%, 100% 20%, 100% 100%, 0% 100%)`}}>
                         <p className="text-red-600 text-lg font-semibold inline-flex items-center">Burn <span className="blinkDot ml-2 text-2xl animate-ping">|</span></p>
                         <div className="box h-20 w-full border border-[#00ffff] bg-red-600 animate-pulse mt-4 flex justify-center items-center">
                    <p className="font-bold">{info.burned !== undefined
                      ? numeral(info.burned).format("0,0.0000")
                      : "0.0000"}</p>
                    </div>
                    </div>
                    </div>
                </div>
              </div>
              <span className="w-full h-[1px] bg-[#00ffff]"/>
      </div>
    </div>
  </section>
  );
}

export default GeneralInfo;
